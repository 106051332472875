import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import DataTables from "../datatable/DataTables";
import swal from "sweetalert";
import api from "../../../helper/axiosInstance";
import RewardModal from "./RewardModal";

const Reward = ({ dataStudent }) => {
  const [listReward, setListReward] = useState([]);
  const [showRewardAdd, setShowRewardAdd] = useState(false);
  const [showRewardEdit, setShowRewardEdit] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    getAchievement();
  }, []);

  //#region API

  const getAchievement = async () => {
    await api
      .get(`/reward-shows?id_student=${dataStudent?.id}`)
      .then((res) => {
        setListReward(res.data.data);
      })
      .catch((err) => {
        toast("Đã có lỗi xảy ra vui lòng thử lại", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const deleteReward = (id) => {
    swal({
      title: `Bạn chắc muốn xóa thành tích?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/reward-delete?id=${id}`)
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            getAchievement();
          })
          .catch((err) => {
            toast(err.message, { type: "error" });
          });
      }
    });
  };
  const dataTableColumns = [
    {
      name: "Tháng",
      selector: (row) => row.month,
      sortable: true,
      cell: (row) => (
        <div>Tháng {new Date(row.month * 1000).getMonth() + 1}</div>
      ),
      wrap: true,
    },
    {
      name: "Nhận xét",
      selector: (row) => row.teacher_comment,
      wrap: true,
    },
    {
      name: "Thành tích",
      selector: (row) => Number(row.achievements),

      cell: (row) =>
        row.achievements?.length > 0
          ? row?.achievements?.map((item, index) => (
              <React.Fragment key={index}>
                {item.achievement} <br />
              </React.Fragment>
            ))
          : null,
      wrap: true,
    },

    {
      name: "Phương thức",
      selector: (row) => row,
      cell: (row) => (
        <div div style={{ display: "flex" }}>
          {new Check().permission(["17", "18", "20"], "or") ? (
            <Button
              variant="warning me-2"
              size="sm"
              type="button"
              onClick={() => {
                setData(row);
                setShowRewardEdit(true);
              }}
              title="Chi tiết"
            >
              <i className="fa-solid fa-pencil"></i>
            </Button>
          ) : null}

          {new Check().permission(["21"]) ? (
            <Button
              variant="danger"
              type="button"
              size="sm"
              onClick={() => {
                deleteReward(row.id);
              }}
              title="Xóa"
            >
              <i className="fas fa-trash"></i>
            </Button>
          ) : null}
        </div>
      ),
      center: true,
    },
  ];

  return (
    <>
      <Card className="mb-4">
        <Card.Header>
          <Card.Title>
            <i className="fas fa-list me-1" /> Danh sách
            {new Check().permission([143]) ? (
              <Button
                variant="success"
                size="sm"
                className="float-end ms-2"
                onClick={() => {
                  setShowRewardAdd(true);
                }}
                title="Thêm"
              >
                + Thêm
              </Button>
            ) : null}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <DataTables data={listReward} columns={dataTableColumns} />
        </Card.Body>
      </Card>
      {showRewardAdd ? (
        <RewardModal
          show={showRewardAdd}
          onHide={() => {
            setShowRewardAdd(false);
          }}
          data={null}
          onSubmit={() => {
            setShowRewardAdd(false);
            getAchievement();
          }}
          studentChoice={dataStudent}
        />
      ) : null}
      {showRewardEdit ? (
        <RewardModal
          show={showRewardEdit}
          onHide={() => {
            setShowRewardEdit(false);
          }}
          data={data}
          onSubmit={() => {
            setShowRewardEdit(false);
            getAchievement();
          }}
          studentChoice={dataStudent}
        />
      ) : null}
    </>
  );
  //#endregion Render
};
export default Reward;
