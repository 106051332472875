import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { Table } from "react-bootstrap";
import { groupArrayByField, dateFormatter } from "../../utils/helpers";
import { INGREDIENT_TYPE, SHOW_INVENTORY } from "../../../../constants";
import api from "../../../../helper/axiosInstance";

const Book1 = ({ school, quantitative, formSetting }) => {
  const costPerDays = quantitative?.costPerDays || [];

  let ingredientArray = [];
  const [ingredents, setIngredent] = useState([]);
  useEffect(() => {
    getIngredentsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getIngredentsData = () => {
    api
      .get(`/ingredent-show?ingredent_type=2`)
      .then((res) => {
        if (res.data.errCode == 0) {
          setIngredent(res.data.ingredents);
        }
      })
      .catch((err) => {});
  };

  quantitative?.times?.map((timeItem) => {
    timeItem?.foods?.map((foodItem) => {
      return foodItem.groups?.map((groupItem) => {
        return groupItem.ingredents?.map((ingredientItem) => {
          const mealQuantity =
            groupItem.more_meal !== null
              ? groupItem.more_meal
              : costPerDays.find(
                  (costPerDay) => costPerDay.id_group === groupItem.id
                )?.meal_quantity || 0;

          ingredientArray.push({
            ...ingredientItem,
            meal_quantity: mealQuantity,
          });

          return ingredientItem;
        });
      });
    });
    return timeItem;
  });

  const groupIngredientArray = groupArrayByField(ingredientArray, "id");

  return (
    <div style={{ overflowX: "scroll" }}>
      <Table bordered style={{ minWidth: 999 }}>
        <thead>
          <tr>
            <th colSpan={15} className="text-center">
              Phục lục 1: MẪU BIỂU GHI CHÉP KIỂM THỰC BA BƯỚC
            </th>
          </tr>
          <tr>
            <td className="align-top" colSpan={8}>
              Tên cơ sở: {school.school_name}
              <br />
              Người kiểm tra: {formSetting.step1_checker}
              <br />
              Thời gian kiểm tra: ngày{" "}
              {new Date(quantitative?.menu_date * 1000).getDate()} tháng{" "}
              {new Date(quantitative?.menu_date * 1000).getMonth() + 1} năm{" "}
              {new Date(quantitative?.menu_date * 1000).getFullYear()}
              <br />
              Địa điểm kiểm tra: {formSetting.step1_check_address}
            </td>
            <th className="align-top" colSpan={7}>
              Mẫu số 1: Kiểm tra trước khi chế biến thức ăn (Bước 1)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={15}>
              I. Thực phẩm tươi sống, đông lạnh: thịt, cá, rau củ, quả....
            </th>
          </tr>
          <tr className="text-center">
            <td className="align-middle" rowSpan={2}>
              TT
            </td>
            <td className="align-middle" rowSpan={2}>
              Tên thực phẩm
            </td>
            <td className="align-middle" rowSpan={2}>
              Thời gian nhập (ngày, giờ)
            </td>
            <td className="align-middle" rowSpan={2}>
              Khối lượng (kg/lít...)
            </td>
            <td className="align-middle" colSpan={3}>
              Nơi cung cấp
            </td>
            <td className="align-middle" rowSpan={2}>
              Chứng từ, hóa đơn
            </td>
            <td className="align-middle" rowSpan={2}>
              Giấy ĐK VS thú y
            </td>
            <td className="align-middle" rowSpan={2}>
              Giấy kiểm dịch
            </td>
            <td className="align-middle" colSpan={2}>
              Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)
            </td>
            <td className="align-middle" colSpan={2}>
              Xét nghiệm nhanh (nếu có) (vi sinh, hóa lý)
            </td>
            <td className="align-middle" rowSpan={2}>
              Biện pháp xử lý/ Ghi chú
            </td>
          </tr>
          <tr className="text-center">
            <td className="align-middle">Tên cơ sở</td>
            <td className="align-middle">Địa chỉ, điện thoại</td>
            <td className="align-middle">Tên người giao hàng</td>
            <td className="align-middle">Đạt</td>
            <td className="align-middle">Không đạt</td>
            <td className="align-middle">Đạt</td>
            <td className="align-middle">Không đạt</td>
          </tr>
          <tr className="text-center">
            <td className="align-middle">(1)</td>
            <td className="align-middle">(2)</td>
            <td className="align-middle">(3)</td>
            <td className="align-middle">(4)</td>
            <td className="align-middle">(5)</td>
            <td className="align-middle">(6)</td>
            <td className="align-middle">(7)</td>
            <td className="align-middle">(8)</td>
            <td className="align-middle">(9)</td>
            <td className="align-middle">(10)</td>
            <td className="align-middle">(11)</td>
            <td className="align-middle">(12)</td>
            <td className="align-middle">(13)</td>
            <td className="align-middle">(14)</td>
            <td className="align-middle">(15)</td>
          </tr>
          {groupIngredientArray.filter(
            (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 1
          ).length > 0 ? (
            groupIngredientArray
              .filter(
                (groupIngredientItem) =>
                  groupIngredientItem[0].ingredent_type === 1
              )
              .map((groupIngredientItem, groupIngredientIndex) => {
                let gramMarket = 0;

                groupIngredientItem.map((ingredientItem) => {
                  const ingredientQuantity = ingredientItem.ing_quantity;
                  gramMarket += Number(
                    (ingredientQuantity * ingredientItem.meal_quantity) /
                      ingredientItem.volume_cook
                  );

                  return ingredientItem;
                });

                gramMarket = Number(gramMarket).toFixed(4);

                return (
                  <tr key={groupIngredientItem[0].id} className="align-middle">
                    <td className="text-center">{groupIngredientIndex + 1}</td>
                    <td>{groupIngredientItem[0].ingredent_name}</td>
                    <td className="text-center">
                      {moment(formSetting.step1_m1_3).format("HH:mm")}
                    </td>
                    <td>
                      <CurrencyFormat
                        value={parseFloat(gramMarket)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={4}
                      />{" "}
                      {groupIngredientItem[0].unit_market_name}
                    </td>
                    <td>{groupIngredientItem[0].supplier_name}</td>
                    <td>{groupIngredientItem[0].supplier_address}</td>
                    <td>{groupIngredientItem[0].supplier_shipper}</td>

                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td className="text-center" colSpan={15}>
                Không có dữ liệu
              </td>
            </tr>
          )}

          {formSetting.step1_show_inventory === 1 ? (
            <>
              <tr>
                <th colSpan={15}>
                  II. Thực phẩm khô và thực phẩm bao gói sẵn, phụ gia thực phẩm
                </th>
              </tr>
              <tr className="text-center">
                <td className="align-middle" rowSpan={2}>
                  TT
                </td>
                <td className="align-middle" rowSpan={2}>
                  Tên thực phẩm
                </td>
                <td className="align-middle" rowSpan={2}>
                  Tên cơ sở sản xuất
                </td>
                <td className="align-middle" rowSpan={2}>
                  Địa chỉ sản xuất
                </td>
                <td className="align-middle" rowSpan={2}>
                  Thời gian nhập (ngày, giờ)
                </td>
                <td className="align-middle" rowSpan={2}>
                  Khối lượng (kg/lít...)
                </td>
                <td className="align-middle" colSpan={3}>
                  Nơi cung cấp
                </td>
                <td className="align-middle" rowSpan={2}>
                  Hạn sử dụng
                </td>
                <td className="align-middle" rowSpan={2}>
                  Điều kiện bảo quản (T° thường, lạnh...)
                </td>
                <td className="align-middle" rowSpan={2}>
                  Chứng từ, hóa đơn
                </td>

                <td className="align-middle" colSpan={2}>
                  Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)
                </td>
                <td className="align-middle" rowSpan={2}>
                  Biện pháp xử lý/ Ghi chú
                </td>
              </tr>
              <tr className="text-center">
                <td className="align-middle">Tên cơ sở</td>
                <td className="align-middle">Tên chủ giao hàng</td>
                <td className="align-middle">Địa chỉ, điện thoại</td>
                <td className="align-middle">Đạt</td>
                <td className="align-middle">Không đạt</td>
              </tr>
              <tr className="text-center">
                <td className="align-middle">(1)</td>
                <td className="align-middle">(2)</td>
                <td className="align-middle">(3)</td>
                <td className="align-middle">(4)</td>
                <td className="align-middle">(5)</td>
                <td className="align-middle">(6)</td>
                <td className="align-middle">(7)</td>
                <td className="align-middle">(8)</td>
                <td className="align-middle">(9)</td>
                <td className="align-middle">(10)</td>
                <td className="align-middle">(11)</td>
                <td className="align-middle">(12)</td>
                <td className="align-middle">(13)</td>
                <td className="align-middle">(14)</td>
                <td className="align-middle">(15)</td>
              </tr>

              {groupIngredientArray.filter(
                (groupIngredientItem) =>
                  groupIngredientItem[0].ingredent_type === 2
              ).length > 0 && formSetting.step1_show_inventory === 1 ? (
                groupIngredientArray
                  .filter(
                    (groupIngredientItem) =>
                      groupIngredientItem[0].ingredent_type === 2
                  )
                  .map((groupIngredientItem, groupIngredientIndex) => {
                    let gramMarket = 0;

                    groupIngredientItem.map((ingredientItem) => {
                      const ingredientQuantity = ingredientItem.ing_quantity;
                      gramMarket += Number(
                        (ingredientQuantity * ingredientItem.meal_quantity) /
                          ingredientItem.volume_cook
                      );
                      return ingredientItem;
                    });

                    gramMarket = Number(gramMarket).toFixed(4);

                    return (
                      <tr
                        key={groupIngredientItem[0].id}
                        className="align-middle"
                      >
                        <td className="text-center">
                          {groupIngredientIndex + 1}
                        </td>
                        <td>{groupIngredientItem[0].ingredent_name}</td>
                        <td>{groupIngredientItem[0]?.facility_name}</td>
                        <td>{groupIngredientItem[0]?.facility_address}</td>

                        <td className="text-center">
                          {moment(formSetting.step1_m2_5).format("HH:mm")}
                        </td>
                        <td>
                          <CurrencyFormat
                            value={parseFloat(gramMarket)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={4}
                          />{" "}
                          {groupIngredientItem[0].unit_market_name}
                        </td>
                        <td>{groupIngredientItem[0].supplier_name}</td>
                        <td>{groupIngredientItem[0].supplier_shipper}</td>
                        <td>{groupIngredientItem[0].supplier_address}</td>
                        <td>
                          {ingredents?.filter(
                            (e) => e.id == groupIngredientItem[0].id
                          )[0]?.ing_exp_date
                            ? dateFormatter(
                                new Date(
                                  ingredents?.filter(
                                    (e) => e.id == groupIngredientItem[0].id
                                  )[0]?.ing_exp_date * 1000
                                )
                              )
                            : ""}
                        </td>
                        <td>{formSetting.step1_m2_11}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <td className="text-center" colSpan={15}>
                    Không có dữ liệu
                  </td>
                </tr>
              )}
            </>
          ) : (
            <></>
          )}
        </tbody>

        <tfoot>
          <tr className="text-center" style={{ height: 50 }}>
            <td colSpan={5}>Ban giám hiệu</td>
            <td colSpan={5}>Tổ trưởng kiểm thực</td>
            <td colSpan={5}>Người kiểm tra</td>
          </tr>
        </tfoot>
      </Table>

      <Table bordered id="book-step-1" className="d-none">
        <thead>
          <tr>
            <th className="text-center">
              Phục lục 1: MẪU BIỂU GHI CHÉP KIỂM THỰC BA BƯỚC
            </th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <td className="align-top">
              Tên cơ sở: {school.school_name}
              <br />
              Người kiểm tra: {formSetting.step1_checker}
              <br />
              Thời gian kiểm tra: ngày{" "}
              {new Date(quantitative?.menu_date * 1000).getDate()} tháng{" "}
              {new Date(quantitative?.menu_date * 1000).getMonth() + 1} năm{" "}
              {new Date(quantitative?.menu_date * 1000).getFullYear()}
              <br />
              Địa điểm kiểm tra: {formSetting.step1_check_address}
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>

            <th className="align-top">
              Mẫu số 1: Kiểm tra trước khi chế biến thức ăn (Bước 1)
            </th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {/* I */}
          <tr>
            <th>
              I. Thực phẩm tươi sống, đông lạnh: thịt, cá, rau củ, quả....
            </th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
          <tr className="text-center">
            <td className="align-middle">TT</td>
            <td className="align-middle">Tên thực phẩm</td>
            <td className="align-middle">Thời gian nhập (ngày, giờ)</td>
            <td className="align-middle">Khối lượng (kg/lít...)</td>
            <td className="align-middle">Nơi cung cấp</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>

            <td className="align-middle">Chứng từ, hóa đơn</td>
            <td className="align-middle">Giấy ĐK VS thú y</td>
            <td className="align-middle">Giấy kiểm dịch</td>
            <td className="align-middle">
              Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)
            </td>
            <td>&nbsp;</td>

            <td className="align-middle">
              Xét nghiệm nhanh (nếu có) (vi sinh, hóa lý)
            </td>
            <td>&nbsp;</td>

            <td className="align-middle">Biện pháp xử lý/ Ghi chú</td>
          </tr>
          <tr className="text-center">
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>

            <td className="align-middle">Tên cơ sở</td>
            <td className="align-middle">Địa chỉ, điện thoại</td>
            <td className="align-middle">Tên người giao hàng</td>

            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>

            <td className="align-middle">Đạt</td>
            <td className="align-middle">Không đạt</td>
            <td className="align-middle">Đạt</td>
            <td className="align-middle">Không đạt</td>

            <td>&nbsp;</td>
          </tr>
          <tr className="text-center">
            <td className="align-middle">(1)</td>
            <td className="align-middle">(2)</td>
            <td className="align-middle">(3)</td>
            <td className="align-middle">(4)</td>
            <td className="align-middle">(5)</td>
            <td className="align-middle">(6)</td>
            <td className="align-middle">(7)</td>
            <td className="align-middle">(8)</td>
            <td className="align-middle">(9)</td>
            <td className="align-middle">(10)</td>
            <td className="align-middle">(11)</td>
            <td className="align-middle">(12)</td>
            <td className="align-middle">(13)</td>
            <td className="align-middle">(14)</td>
            <td className="align-middle">(15)</td>
          </tr>

          {groupIngredientArray.filter(
            (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 1
          ).length > 0 ? (
            groupIngredientArray
              .filter(
                (groupIngredientItem) =>
                  groupIngredientItem[0].ingredent_type === 1
              )
              .map((groupIngredientItem, groupIngredientIndex) => {
                let gramMarket = 0;

                groupIngredientItem.map((ingredientItem) => {
                  const ingredientQuantity = ingredientItem.ing_quantity;
                  gramMarket += Number(
                    (ingredientQuantity * ingredientItem.meal_quantity) /
                      ingredientItem.volume_cook
                  );
                  return ingredientItem;
                });

                gramMarket = Number(gramMarket).toFixed(4);

                return (
                  <tr key={groupIngredientItem[0].id} className="align-middle">
                    <td className="text-center">{groupIngredientIndex + 1}</td>
                    <td>{groupIngredientItem[0].ingredent_name}</td>
                    <td className="text-center">
                      {moment(formSetting.step1_m1_3).format("HH:mm")}
                    </td>
                    <td>
                      <CurrencyFormat
                        value={parseFloat(gramMarket)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={4}
                      />{" "}
                      {groupIngredientItem[0].unit_market_name}
                    </td>
                    <td>{groupIngredientItem[0].supplier_name}</td>
                    <td>
                      {groupIngredientItem[0].supplier_address || <>&nbsp;</>}
                    </td>
                    <td>
                      {groupIngredientItem[0].supplier_shipper || <>&nbsp;</>}
                    </td>

                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td className="text-center">Không có dữ liệu</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          )}

          {/* II */}
          {formSetting.step1_show_inventory === SHOW_INVENTORY.TRUE ? (
            groupIngredientArray.filter(
              (groupIngredientItem) =>
                groupIngredientItem[0].ingredent_type === INGREDIENT_TYPE.STORE
            ).length > 0 ? (
              <>
                <tr>
                  <th>
                    II. Thực phẩm khô và thực phẩm bao gói sẵn, phụ gia thực
                    phẩm
                  </th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
                <tr className="text-center">
                  <td className="align-middle">TT</td>
                  <td className="align-middle">Tên thực phẩm</td>
                  <td className="align-middle">Tên cơ sở sản xuất</td>
                  <td className="align-middle">Địa chỉ sản xuất</td>
                  <td className="align-middle">Thời gian nhập (ngày, giờ)</td>
                  <td className="align-middle">Khối lượng (kg/lít...)</td>
                  <td className="align-middle">Nơi cung cấp</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>

                  <td className="align-middle">Hạn sử dụng</td>
                  <td className="align-middle">
                    Điều kiện bảo quản (T° thường, lạnh...)
                  </td>
                  <td className="align-middle">Chứng từ, hóa đơn</td>

                  <td className="align-middle">
                    Kiểm tra cảm quan (màu, mùi, vị, trạng thái, bảo quản...)
                  </td>
                  <td>&nbsp;</td>

                  <td className="align-middle">Biện pháp xử lý/ Ghi chú</td>
                </tr>
                <tr className="text-center">
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>

                  <td className="align-middle">Tên cơ sở</td>
                  <td className="align-middle">Tên chủ giao hàng</td>
                  <td className="align-middle">Địa chỉ, điện thoại</td>

                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>

                  <td className="align-middle">Đạt</td>
                  <td className="align-middle">Không đạt</td>

                  <td>&nbsp;</td>
                </tr>
                <tr className="text-center">
                  <td className="align-middle">(1)</td>
                  <td className="align-middle">(2)</td>
                  <td className="align-middle">(3)</td>
                  <td className="align-middle">(4)</td>
                  <td className="align-middle">(5)</td>
                  <td className="align-middle">(6)</td>
                  <td className="align-middle">(7)</td>
                  <td className="align-middle">(8)</td>
                  <td className="align-middle">(9)</td>
                  <td className="align-middle">(10)</td>
                  <td className="align-middle">(11)</td>
                  <td className="align-middle">(12)</td>
                  <td className="align-middle">(13)</td>
                  <td className="align-middle">(14)</td>
                  <td className="align-middle">(15)</td>
                </tr>

                {groupIngredientArray
                  .filter(
                    (groupIngredientItem) =>
                      groupIngredientItem[0].ingredent_type === 2
                  )
                  .map((groupIngredientItem, groupIngredientIndex) => {
                    let gramMarket = 0;

                    groupIngredientItem.map((ingredientItem) => {
                      const ingredientQuantity = ingredientItem.ing_quantity;
                      gramMarket += Number(
                        (ingredientQuantity * ingredientItem.meal_quantity) /
                          ingredientItem.volume_cook
                      );
                      return ingredientItem;
                    });

                    gramMarket = Number(gramMarket).toFixed(4);

                    return (
                      <tr
                        key={groupIngredientItem[0].id}
                        className="align-middle"
                      >
                        <td className="text-center">
                          {groupIngredientIndex + 1}
                        </td>
                        <td>{groupIngredientItem[0].ingredent_name}</td>
                        <td>
                          {groupIngredientItem[0]?.facility_name || <>&nbsp;</>}
                        </td>
                        <td>
                          {groupIngredientItem[0]?.facility_address || (
                            <>&nbsp;</>
                          )}
                        </td>
                        <td className="text-center">
                          {moment(formSetting.step1_m1_3).format("HH:mm")}
                        </td>
                        <td>
                          <CurrencyFormat
                            value={parseFloat(gramMarket)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={4}
                          />{" "}
                          {groupIngredientItem[0].unit_market_name}
                        </td>
                        <td>{groupIngredientItem[0].supplier_name}</td>
                        <td>
                          {groupIngredientItem[0].supplier_shipper || (
                            <>&nbsp;</>
                          )}
                        </td>
                        <td>
                          {groupIngredientItem[0].supplier_address || (
                            <>&nbsp;</>
                          )}
                        </td>
                        <td>
                          {ingredents?.filter(
                            (e) => e.id == groupIngredientItem[0].id
                          )[0]?.ing_exp_date ? (
                            dateFormatter(
                              new Date(
                                ingredents?.filter(
                                  (e) => e.id == groupIngredientItem[0].id
                                )[0]?.ing_exp_date * 1000
                              )
                            )
                          ) : (
                            <>&nbsp;</>
                          )}
                        </td>

                        <td>{formSetting.step1_m2_11 || <>&nbsp;</>}</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    );
                  })}
              </>
            ) : (
              <tr>
                <td className="text-center">Không có dữ liệu</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            )
          ) : (
            <></>
          )}
        </tbody>
        <tfoot>
          <tr className="text-center" style={{ height: 50 }}>
            <td>Ban giám hiệu</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>

            <td>Tổ trưởng kiểm thực</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>

            <td>Người kiểm tra</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default Book1;
