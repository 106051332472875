import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import Check from "../other/Check";
import MedicModal from "./MedicModal";
import { dateFormatter, getDate, getTime } from "../utils/helpers";
import MedicEditModal from "./MedicEditModal";
import api from "../../../helper/axiosInstance";

class MedicationIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      modalRoleShow: false,

      role: {},
      selectedRows: [],
      checkload: true,
      editModal: false,
      filterText: "",
      studentsSearch: [],
      startDate: new Date(),
      endDate: new Date(),
      typeModal: "",

      //
      evaluation: [],
      showMedicModal: false,
      itemMedic: {},
      ids: [],
      isTableSelectable: true,
    };
  }

  componentDidMount() {
    document.title = "Sổ nhận thuốc";
    this.setState(
      {
        startDate: new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)),
        endDate: this.lastDayy(
          new Date().getFullYear(),
          Number(new Date().getMonth()) + 1
        ),
      },
      () => this.getevaluation()
    );
  }

  getevaluation = () => {
    api
      .get(
        `/medication-reminder-shows?startDate=${parseInt(
          this.state.startDate / 1000
        )}&endDate=${parseInt(this.state.endDate / 1000)}`
      )
      .then((res) => {
        this.setState({ checkload: false, isTableSelectable: true });
        this.setState({ evaluation: res.data.data?.docs }, () =>
          this.filterStudent("")
        );
      });
  };

  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }
  filterStudent = (text) => {
    this.setState({ isTableSelectable: false });
    this.setState({ filterText: text });
    const filteredStudents = this.state.evaluation?.filter((student) =>
      this.toSlug(student?.student?.student_name)?.includes(
        this.toSlug(text?.trim())
      )
    );
    this.setState({
      studentsSearch: filteredStudents,
      filterText: text,
    });
  };

  destroyMedic = async (id) => {
    swal({
      title: `Bạn chắc muốn xóa nhắc nhỡ?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/medication-reminder-delete?id=${id}`)
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            this.getevaluation();
          })
          .catch((err) => {
            toast(err.message, { type: "error" });
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  lastDayy = (year, month) => {
    const nextMonth = new Date(year, month, 1);
    nextMonth.setDate(nextMonth.getDate() - 1);
    return new Date(nextMonth.setHours(23, 59, 59));
  };
  onSelectedRowsChange = (rows) => {
    this.setState({ ids: rows });
  };
  approve = () => {
    let data = {
      ids: this.state.ids?.map((e) => e.id),
    };

    api
      .patch(`medication-reminder-approve`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Thành công", { type: "success" });
        this.setState({ isTableSelectable: false, ids: [] });
        this.getevaluation();
      })
      .catch((error) => {
        this.setState({ err: error.response.data.messages });
      });
  };
  dataTable() {
    const columns = [
      {
        name: "Tên học sinh",
        selector: (row) => row.student?.student_name,
        sortable: true,
        wrap: true,
      },
      {
        name: "Lớp",
        selector: (row) => row.student?.classes[0]?.class_name,
        sortable: true,
        wrap: true,
      },
      {
        name: "Ngày",
        selector: (row) => parseInt(row.date_time),
        sortable: true,
        cell: (row) => (
          <div>{dateFormatter(new Date(row.date_time * 1000))}</div>
        ),
        wrap: true,
        center: true,
      },
      {
        name: "Thời gian",
        selector: (row) => parseInt(row.date_time),
        sortable: true,
        center: true,
        cell: (row) => (
          <div>
            {row.description?.times?.map((time) => {
              return <div>{getTime(time.time)}</div>;
            })}
          </div>
        ),
      },
      {
        name: "Trạng thái",
        selector: (row) => row.status,
        cell: (row) =>
          row.status == "approved" ? (
            <div className="text-success">Đã duyệt</div>
          ) : (
            <div className="text-danger">Chưa duyệt</div>
          ),
      },
      {
        name: "Nội dung",
        selector: (row) => row.date_time,
        cell: (row) => <div>{row.description?.note}</div>,
      },

      {
        name: "",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items });
    };

    if (this.state.studentsSearch?.length > 0) {
      this.state.studentsSearch.map((item, i) => {
        data.push({
          ...item,
          setting: (
            <>
              {new Check().permission(["62"]) ? (
                <Button
                  size="sm"
                  variant="primary me-2"
                  type="button"
                  onClick={() => {
                    this.setState({ showMedicModal: true, itemMedic: item });
                  }}
                  title="Chi tiết"
                >
                  {/* <i className="fa-solid fa-pencil"></i> */}
                  <i className="fa-solid fa-circle-info"></i>
                </Button>
              ) : null}

              {new Check().permission(["65"]) ? (
                <Button
                  size="sm"
                  variant="danger"
                  type="button"
                  onClick={() => this.destroyMedic(item.id)}
                  disabled={item.pay_status}
                  title="Xóa"
                  hidden
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </>
          ),
        });

        return item;
      });
    }
    const selectableRowDisabled = (row) => {
      return row.status == "approved";
    };
    return (
      <DataTables
        data={data}
        columns={columns}
        selectedRows={selectedRows}
        selectableRows
        selectableRowDisabled={selectableRowDisabled}
        clearSelectedRows={this.state.isTableSelectable}
        onSelectedRowsChange={(item) =>
          this.onSelectedRowsChange(item.selectedRows)
        }
      />
    );
  }

  refreshRole = () => {
    this.setState({
      role: {},
    });
  };

  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false, editModal: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false, editModal: false });
      this.refreshRole();
      this.getevaluation();
    };

    return (
      <>
        <Card.Header className="px-2 py-0">
          <div className="d-flex align-items-center justify-content-between">
            <Card.Title className="mt-1">
              <i className="fas fa-list me-1"></i> Sổ nhận thuốc tháng{" "}
              {this.state.startDate?.getMonth() + 1}/
              {this.state.startDate?.getFullYear()}{" "}
              <input
                type="month"
                name="month"
                style={{
                  border: "none",
                  width: 39,
                  backgroundColor: "rgb(16,108,252)",
                  borderRadius: 5,
                }}
                className="me-2 mb-2"
                onChange={(e) => {
                  if (e.target.value) {
                    const [year, month] = e.target.value.split("-");
                    this.setState(
                      {
                        startDate: new Date(year, month - 1),
                        endDate: this.lastDayy(
                          new Date(year, month - 1).getFullYear(),
                          Number(new Date(year, month - 1).getMonth()) + 1
                        ),
                      },
                      () => this.getevaluation()
                    );
                  }
                }}
              />
            </Card.Title>
            {new Check().permission([87]) ? (
              <Button
                type="button"
                variant="success"
                size="sm"
                style={{ float: "right" }}
                disabled={this.state.ids?.length < 1}
                onClick={() => this.approve()}
              >
                <i className="fa-solid fa-check"></i> Duyệt
              </Button>
            ) : null}
          </div>
        </Card.Header>

        <Card.Body>
          <Form.Control
            className="mb-3"
            style={{ height: 39 }}
            type="text"
            placeholder="Nhập tên học sinh"
            value={this.state.filterText}
            onChange={(e) => {
              this.filterStudent(e.target.value);
            }}
          />

          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>

        {this.state.editModal ? (
          <>
            <MedicModal
              show={this.state.editModal}
              onHide={modalClose}
              modal={{
                title: "Thêm kỳ thuốc",
                button: (
                  <>
                    <i className="fa-solid fa-check" /> Lưu
                  </>
                ),
              }}
              type={this.state.typeModal}
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}

        {this.state.showMedicModal ? (
          <>
            <MedicEditModal
              show={this.state.showMedicModal}
              onHide={() => this.setState({ showMedicModal: false })}
              modal={{
                title: "Chi tiết thuốc",
                button: (
                  <>
                    <i className="fa-solid fa-check" /> Lưu
                  </>
                ),
              }}
              type={"TM"}
              datarole={this.state.itemMedic}
              submit={() => {
                this.setState({ showMedicModal: false });
                this.getevaluation();
              }}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 ">
            <Breadcrumb.Item active>Học sinh</Breadcrumb.Item>
            <Breadcrumb.Item active>Sổ nhận thuốc</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default MedicationIndex;
